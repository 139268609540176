<template>
    <div>
        <el-form :inline="true" :model="warnForm" >
            <el-form-item label="故障分类" prop="majorFaultType">
                <el-select v-model="warnForm.majorFaultType" placeholder="请选择" clearable @change='majorChangeSearch'>
                    <el-option v-for="item in majorOption" :key="item.majorFaultType" :label="item.majorFaultName" :value="item.majorFaultType">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="故障类型" prop="minorFaultName">
                <el-select v-model="warnForm.minorFaultName" placeholder="请选择" clearable>
                    <el-option v-for="item in minorOption" :key="item.minorFaultType" :label="item.minorFaultName" :value="item.minorFaultName">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="告警时间" prop="warnTime">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="warnForm.warnTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getWarnInfoData">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="warnInfoData" border size="small" stripe style="width: 100%"
                  :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
            <el-table-column prop="majorFaultName" label="故障分类"/>
            <el-table-column prop="minorFaultName" label="故障类型" />
            <el-table-column prop="seriousLevel" label="故障严重程度" >
                <template slot-scope="scope">
                    <div v-html="getSeriousIcon(scope.row.seriousLevel)"></div>
                </template>
            </el-table-column>
            <el-table-column prop="reportTime" label="告警时间" />
        </el-table>
        <div style="margin-top: 15px;text-align: right;">
            <el-pagination background layout="total, prev, pager, next, jumper" :total="total"
                           @current-change="handleCurrentChange" :current-page.sync="curr"/>
        </div>
    </div>
</template>
<script>
    import {queryMajorOption, queryWarnInfoData, queryMinorOption} from "../../../api/station/deviceInfoApi";
    export default {
        name: "warnInfoVisible",
        data() {

            return {

                warnForm: {

                    majorFaultType: '',
                    minorFaultType: '',
                    minorFaultName: '',
                    warnTime: ''
                },
                majorOption: [],
                minorOption: [],
                currWholeId: '',
                warnInfoData: [],
                orgOption:[],
                listLoading: false,
                total: 0,
                curr: 1,
                size: 10
            }
        },
        methods: {

            // 初始化告警信息页面
            initWarnInfoPage(id) {

                this.warnForm.majorFaultType = '';
                this.warnForm.minorFaultName = '';
                this.warnForm.warnTime = '';
                this.currWholeId = id;
                this.getMajorOption();
                this.getWarnInfoData();
            },
            // 查询故障分类
            getMajorOption() {

                queryMajorOption().then((res) => {

                    this.majorOption = res.data;
                }).catch(err=> console.log(err));
            },
            // 查询故障类型
            getMinorOption() {

                let params = {

                    type: this.warnForm.majorFaultType
                };
                queryMinorOption(params).then((res) =>{

                    this.minorOption = res.data;
                }).catch(err=> console.log(err));
            },
            // 故障分类改变触发
            majorChangeSearch() {

                this.getMinorOption();
            },
            // 获取严重程度图标
            getSeriousIcon(level) {

                if (level == 'middle') {

                    return "<div class='seriousCircle' style='background-color: #e6a23c'>中</div>";
                } else if (level == 'senior') {

                    return "<div class='seriousCircle' style='background-color: #f56c6c'>高</div>";
                }
            },
            // 查询警告信息
            getWarnInfoData() {

                let time = this.warnForm.warnTime;
                let sTime = '';
                let eTime = '';
                if (time != '' && time != null) {

                    sTime = time[0];
                    eTime = time[1];
                }
                let params = {

                    curr: this.curr,
                    size: this.size,
                    deviceWholeId: this.currWholeId,
                    majorFaultType: this.warnForm.majorFaultType,
                    minorFaultName: this.warnForm.minorFaultName,
                    startTime: sTime,
                    endTime: eTime
                };
                queryWarnInfoData(params).then((res) => {

                    this.warnInfoData = res.data.listResult;
                    this.total = res.data.totalNum;
                }).catch(err=> console.log(err));
            },
            // 关闭页面
            closeWarnInfoPage() {

                this.$parent.closeWarnInfoPage();
            },
            handleCurrentChange(val) {

                this.curr = val;
                this.getWarnInfoData();
            }
        }
    }
</script>
<style>
    .seriousCircle {

        width: 30px;
        height: 30px;
        border-radius:50%;
        line-height: 2.5;
        color: white;
        text-align: center;
    }
</style>